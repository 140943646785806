<template>
  <LoginForm :form-pending="formPending" :login-error="loginError" @submit:form="onSubmit($event)" />
</template>

<script setup lang="ts">
  import { useAuthStore } from '~/stores/api/AuthStore';
  import LoginForm from '~/forms/security/LoginForm.vue';
  import type LoginFormInterface from '~/forms/security/LoginFormInterface';
  import type { Ref } from 'vue';
  import type { ClientError } from '~/api/error/ClientError';

  const formPending: Ref<boolean> = ref(false);
  const loginError: Ref<boolean> = ref(false);
  const authStore = useAuthStore();

  const onSubmit = async (loginForm: Ref<LoginFormInterface>) => {
    try {
      formPending.value = true;
      await authStore.login(loginForm.value.login, loginForm.value.password);
      await authStore.authenticate();
      formPending.value = false;

      navigateTo('/structures');
    } catch (e) {
      loginError.value = true;
      formPending.value = false;
      const error = e as ClientError;
    }
  };
</script>
