<template>
  
<FormKitLazyProvider config-file="true">
<div class="pb-10">
    <h1 class="text-3xl font-bold">Connectez-vous à Optiago</h1>
  </div>

  <FormKit v-model="currentLoginForm" type="form" :actions="false" :config="{ validationVisibility: 'submit' }" :incomplete-message="false" @submit="submit">
    <div>
      <FormKit
        type="text"
        label="Adresse mail"
        placeholder="Adresse mail"
        name="login"
        :input-class="InputText.inputClass"
        :label-class="InputText.labelClass"
        :messages-class="InputText.messagesClass"
        :validation="InputText.validationMail"
        :validation-messages="InputText.validationMailMessages"
        tabindex="1"
      />
    </div>

    <div class="mt-5">
      <div class="flex justify-between">
        <label class="formkit-label text-sm font-semibold" for="input_2">Mot de passe</label>
        <NuxtLink to="/forgot-password/request" class="text-sm text-gray-500" tabindex="4">J'ai oublié mon mot de passe</NuxtLink>
      </div>
      <div class="size-2.5" />
      <FormKit
        type="password"
        placeholder="Mot de passe"
        name="password"
        required
        :input-class="InputPassword.inputClass"
        :label-class="InputPassword.labelClass"
        :messages-class="InputText.messagesClass"
        :validation="InputPassword.validationPassword"
        tabindex="1"
      />
    </div>

    <BaseAlert v-if="props.loginError" type="error" message="L’e-mail ou le mot de passe fourni est incorrect." class-list="mt-5" />

    <div class="mt-5">
      <FormKit type="submit" input-class="w-full button button-primary button-base button-solid" tabindex="3">
        <ButtonLoadingMessage loading-message="Connexion en cours" :on-loading="formPending"> Se connecter </ButtonLoadingMessage>
      </FormKit>
    </div>
  </FormKit>
</FormKitLazyProvider>

</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
  import { InputText } from '~/form-kit/InputText';
  import { InputPassword } from '~/form-kit/InputPassword';
  import ButtonLoadingMessage from '~/components/ui/ButtonLoadingMessage.vue';
  import type LoginFormInterface from '~/forms/security/LoginFormInterface';
  import type { Ref } from 'vue';
  import BaseAlert from '~/components/ui/BaseAlert.vue';

  const props = defineProps<{
    formPending: boolean;
    loginError: boolean;
  }>();

  const currentLoginForm: Ref<LoginFormInterface> = ref({
    login: '',
    password: ''
  });

  const emit = defineEmits(['submit:form']);
  const submit = () => {
    emit('submit:form', currentLoginForm);
  };
</script>
